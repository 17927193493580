import { client } from "@/utils/rest.js";

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        projects: null,
        hasAccess: null
    },
    mutations: {
        setProjects(state, projects) {
            state.projects = projects
        },
        setAccess(state, access) {
            state.hasAccess = access
        }
    },
    actions: {
        getAccess({commit}, data) {
            client.get("/login/").then(resp => {
                commit("setAccess", resp.data.can_load_receipt)
            })
        },
        singIn(_, data) {
            return client.post("/login/", data)
        },
        getLinkName(_, id) {
            return client.get(`/link/${id}`)
        },
        getLinkForPay(_, { id, data }) {
            return client.post(`/link/${id}`, data)
        },
        getProjects({ commit }) {
            client.get("/project/").then((resp) => {
                if (resp) commit("setProjects", resp.data.projects)
            })
        },
        getProject(_, id) {
            return client.get(`/project/${id}`)
        },
        createProject(_, data) {
            return client.post("/project/", data)
        },
        editProject(_, { id, data }) {
            return client.put(`/project/${id}`, data)
        },
        putOnPause(_, id) {
            return client.get(`/project/${id}/pause`)
        },
        resumeProject(_, id) {
            return client.get(`/project/${id}/resume`)
        },
        closeProject(_, id) {
            return client.get(`/project/${id}/close`)
        },
        getReceipt(_, id) {
            return client.get(`/receipt/${id}`, {responseType: 'blob'})
        }



    }
})