import Vue from 'vue'
import App from './App.vue'
import router from "@/router"
import client from "./utils/rest";
import store from "./store";
import VueClipboard from 'vue-clipboard2';
import VueMask from 'v-mask'
import VueQRCodeComponent from 'vue-qrcode-component';
 
Vue.component('qr-code', VueQRCodeComponent);
Vue.use(VueClipboard);
Vue.use(VueMask);
Vue.use(client);

Vue.config.productionTip = false
new Vue({
  store, router,
  render: h => h(App),
}).$mount('#app')


