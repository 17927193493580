import Vue from "vue";
import VueRouter from "vue-router";


Vue.use(VueRouter);

const routes = [
    { path: '*', redirect: "/home" },
    { path: '/home', component: () => import("../components/Home"), name: "Home" },
    { path: '/bill/:id', component: () => import("../components/PayForm/PayIdx.vue"), name: "Project" },
    { path: '/admin/projects', component: () => import("../components/Projects/ProjectsIdx.vue"), name: "Projects" },
    { path: '/admin/login', component: () => import("../components/Login"), name: "Login" },
]

const router = new VueRouter({
    // mode: 'history',
    routes,
});

export default router;


