
import axios from "axios";
import router from "@/router";

const BASE_API_URL = process.env.VUE_APP_API;

export const client = axios.create({
  baseURL: BASE_API_URL,
});


client.interceptors.request.use(
  (config) => config,
  (error) => {
    Promise.reject(error);
  }
);
client.interceptors.request.use(
  (config) => beforeRequest(config),
  (error) => {
    Promise.reject(error);
  }
);
client.interceptors.response.use(
  (response) => response,
  (error) => beforeResponseError(error, client)
);

function beforeRequest(config) {
  const accessToken = localStorage.access_token;
  if (accessToken && accessToken !== "null") {
    if (config.url !== "/login") {
      config.headers.Authorization = "Bearer " + accessToken;
    }
    return config;
  }
  else {
    if (router.history.current.name !== "Login" && router.history.current.name !== "Project") {
      router.replace({ name: 'Login' })
    }
  }
  return config;
}


function beforeResponseError(error, client) {
  const {
    config,
    response: { status },
  } = error;
  if (status === 401) {
    router.replace({ name: 'Login' })
  } else {
    return Promise.reject(error);
  }
}

export default {
  install: function (Vue) {
    Vue.prototype.$client = client;
  },
};
